import { create } from 'zustand'
import { logout } from './auth'

interface ApiErrorStates {
  isMissingPermissions?: boolean
  isServerError?: boolean
  isUnauthorized?: boolean
  isInvalidPassword?: boolean
  isNotFound?: boolean
  isUserNotFound?: boolean
  isUnavailable?: boolean
  isInternalServerError?: boolean
  isServerNotReachable?: boolean
  isBadRequest?: boolean
}

interface ApiErrorOptions {
  onRetry?: () => void
  onDismiss?: () => void
  isDismissible?: boolean
  requestId?: string
  method?: string
  endpoint?: string
  type?: string
}

interface ApiErrorStore extends ApiErrorStates, ApiErrorOptions {
  setError: (error: Partial<ApiErrorStates>, options?: ApiErrorOptions) => void
  statusCode: number | undefined
  setStatusCode: (code: number) => void
  serverErrorResponse: string
  setServerErrorResponse: (serverResponse: string) => void
  clearError: () => void
  retry: () => void
  dismiss: () => void
  hasError: () => boolean
}

export const useApiError = create<ApiErrorStore>((set, get) => ({
  isMissingPermissions: false,
  isServerError: false,
  isUnauthorized: false,
  isInvalidPassword: false,
  isNotFound: false,
  isUserNotFound: false,
  isUnavailable: false,
  isDismissible: false,
  isInternalServerError: false,
  isServerNotReachable: false,
  isBadRequest: false,
  serverErrorResponse: '',
  statusCode: undefined,

  hasError: () =>
    get().isMissingPermissions ||
    get().isServerError ||
    get().isUnauthorized ||
    get().isInvalidPassword ||
    get().isNotFound ||
    get().isUserNotFound ||
    get().isInternalServerError ||
    get().isServerNotReachable ||
    get().isUnavailable ||
    get().isBadRequest ||
    false,

  setError: (error: Partial<ApiErrorStates>, options?: ApiErrorOptions) =>
    set({
      isMissingPermissions: error.isMissingPermissions || false,
      isServerError: error.isServerError || false,
      isUnauthorized: error.isUnauthorized || false,
      isInvalidPassword: error.isInvalidPassword || false,
      isNotFound: error.isNotFound || false,
      isUserNotFound: error.isUserNotFound || false,
      isUnavailable: error.isUnavailable || false,
      isInternalServerError: error.isInternalServerError || false,
      isServerNotReachable: error.isServerNotReachable || false,
      isBadRequest: error.isBadRequest || false,

      onRetry: options?.onRetry,
      onDismiss: options?.onDismiss,
      isDismissible: options?.isDismissible || false,
      requestId: options?.requestId,
      method: options?.method,
      endpoint: options?.endpoint,
      type: options?.type,
    }),

  setStatusCode: code => set(state => ({ ...state, statusCode: code })),

  setServerErrorResponse: errorResponse =>
    set(state => ({ ...state, serverErrorResponse: errorResponse })),

  clearError: () => {
    set({
      isMissingPermissions: false,
      isServerError: false,
      isUnauthorized: false,
      isInvalidPassword: false,
      isNotFound: false,
      isUserNotFound: false,
      isUnavailable: false,
      onRetry: undefined,
      onDismiss: undefined,
      isDismissible: false,
      requestId: undefined,
      method: undefined,
      endpoint: undefined,
      isInternalServerError: false,
      isServerNotReachable: false,
      isBadRequest: false,
      serverErrorResponse: '',
      statusCode: undefined,
    })
  },

  dismiss: () => {
    const { onDismiss, clearError } = get()
    clearError && clearError()
    onDismiss && onDismiss()
  },

  retry: () => {
    const { onRetry, clearError } = get()
    clearError && clearError()
    onRetry && onRetry()
  },
}))

export const handleApiError = (
  response: Response,
  data: any,
  requestId: string,
  requestParams: any,
  onRetry?: () => void,
  onDismiss?: () => void
) => {
  let error: ApiErrorStates = {}
  let options: ApiErrorOptions = {
    requestId,
    endpoint: requestParams.path,
    method: requestParams.options.method || 'get',
    onDismiss,
    onRetry,
  }

  // Invalid Credentials: The username or password provided by the user is incorrect.
  // Missing Authentication Token: The request does not include the necessary authentication token or credentials.
  // Expired Token: The authentication token provided has expired and is no longer valid.
  // Invalid Token: The authentication token provided is invalid or malformed.

  switch (response.status) {
    case 400:
      error.isBadRequest = true
      options.isDismissible = true
      options.type = 'https://fl.bi/api-error-bad-request'
      break
    case 401:
      // Invalid Credentials: The password provided is incorrect
      if (data.message === 'Password invalid') {
        error.isInvalidPassword = true
      }
      // Missing Authentication Token or
      // Expired Token or
      // Invalid Token
      else {
        error.isUnauthorized = true
      }
      options.isDismissible = true
      // Make it possible for users to eject their session in this case.
      options.onDismiss = () => {
        logout()
        onDismiss && onDismiss()
      }
      // Disallow retrying these requests - the only way to fix it is to login again.
      options.onRetry = undefined
      options.type = 'https://fl.bi/api-error-unauthorized'
      break
    case 403:
      error.isMissingPermissions = true
      options.isDismissible = true
      options.type = 'https://fl.bi/api-error-missing-permissions'
      break
    case 404:
      // User Not Found
      if (data.message === 'User does not exist') {
        error.isUserNotFound = true
      } else {
        error.isNotFound = true
      }
      options.isDismissible = true
      options.type = 'https://fl.bi/api-error-resource-not-found'
      break
    case 500:
      error.isInternalServerError = true
      options.isDismissible = true
      options.type = 'https://fl.bi/api-error-resource-not-found'
      break
    case 502:
    case 503:
    case 504:
      error.isServerNotReachable = true
      options.isDismissible = true
      options.type = 'https://flbi.net/api-error-server-not-reachable'
      break
    default:
      error.isServerError = true
      options.isDismissible = true
      options.type = 'https://flbi.net/api-error'
  }

  useApiError.getState().setError(error, options)
}
