import { useEffect } from 'react'
import { Stimulsoft, StiOptions } from 'stimulsoft-reports-js/Scripts/stimulsoft.blockly.editor'
import { useConfig } from './state/config'

const Reports = () => {
  const { apiUrl } = useConfig.getState()
  console.log('apiUrl: ', apiUrl)

  useEffect(() => {
    // Initialize Stimulsoft options and designer
    const options = new Stimulsoft.Designer.StiDesignerOptions()
    const designer = new Stimulsoft.Designer.StiDesigner(options, 'StiDesigner', false)

    if (!designer) {
      console.error('Failed to create Stimulsoft designer.')
      return
    }

    // Configure Stimulsoft WebServer options
    // StiOptions.WebServer.url = `${API_URL}:${PORT}`
    // StiOptions.WebServer.encryptData = false
    // StiOptions.WebServer.checkDataAdaptersVersion = false

    // Set localization file
    Stimulsoft.Base.Localization.StiLocalization.setLocalizationFile('localization/en.xml', true)

    // Define onSaveReport handler
    designer.onSaveReport = args => {
      try {
        var report = args.report
        var str = report.saveToJsonString()
        Stimulsoft.System.StiObject.saveAs(str, 'Report.mrt', 'application/json')
      } catch (error) {
        console.error('Error saving report: ', error)
      }
    }

    try {
      const report = Stimulsoft.Report.StiReport.createNewReport()
      report.loadFile('reports/Designer_Report.mrt')
      designer.report = report
    } catch (error) {
      console.error('Error loading report: ', error)
    }

    designer.renderHtml('designerContent')
  }, [])

  return <div id="designerContent"></div>
}

export default Reports
