import React, { useRef, useEffect } from 'react'
import { embedDashboard } from '@superset-ui/embedded-sdk'
import Box from '@mui/material/Box'

import { useConfig } from './state/config'
import { fetchInstanceDashboardToken, useInstance } from './state/auth/currentInstance'

export const Dashboard = () => {
  const gsr_inst = useInstance(instance => instance.gsr_inst)
  const chartsUrl = useConfig(config => config.chartsUrl)
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!containerRef.current || !chartsUrl) return

    embedDashboard({
      fetchGuestToken: fetchInstanceDashboardToken,
      id: 'd2508e32-599b-4914-9a01-0d0e5b1a46bd',
      supersetDomain: chartsUrl,
      mountPoint: containerRef.current,
      dashboardUiConfig: {
        hideTitle: true,
        hideChartControls: true,
        hideTab: true,
      },
    })
  }, [chartsUrl, gsr_inst])

  return (
    <Box
      ref={containerRef}
      sx={{
        height: '100%',
        iframe: {
          border: 'none',
          width: '100%',
          height: '100%',
        },
      }}
    />
  )
}
