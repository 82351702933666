import { create } from 'zustand'
import { shallow } from 'zustand/shallow'
import { subscribeWithSelector } from 'zustand/middleware'

import { init as initClient } from './auth/currentClient'
import { init as initInstance } from './auth/currentInstance'
import { init as initUser } from './auth/currentUser'
import { request } from './api'

type AuthStore = {
  userId?: string
  isLoggedIn?: boolean
  isAuthenticating?: boolean
  hadError?: boolean
  isLoading: () => boolean
}

export const authStore = create<AuthStore>()(
  subscribeWithSelector((set, get) => ({
    isLoggedIn: false,
    // We may know the userId earlier than having a token or being set up completely. This is due to client-side
    // caching to restore the session after page reloads. To make it possible for login components to display a
    /// meaningful state, we provide a getter via the store. Note how this returns true even after calling login().
    // This makes it possible for login components to entirely rely on isLoading for indicators.
    isLoading: () =>
      !get().hadError && ((get().userId && !get().isLoggedIn) || !!get().isAuthenticating),
  }))
)
export const useAuth = authStore

authStore.subscribe(
  state => state.userId,
  async (userId, previousUserId) => {
    if (!userId && previousUserId) {
      // The user got logged out.
      return window.localStorage.removeItem('auth')
    }

    if (userId && userId !== previousUserId) {
      // UserId changed, probably a login.
      window.localStorage.setItem('auth', JSON.stringify(authStore.getState()))

      // Kick off the current* stores.
      try {
        await initUser()
        await initClient()
        await initInstance()

        // Now that we're all set, let the world know we're ready to rumble.
        authStore.setState({ isLoggedIn: true })
      } catch (e) {
        console.error(e)
        authStore.setState({ hadError: true })
      }
    }
  },
  { equalityFn: shallow }
)

export const login = async (username: string, password: string) => {
  authStore.setState({ isAuthenticating: true, hadError: false })

  try {
    const {
      data: { userId },
    } = await request('/auth/signin', {
      method: 'post',
      credentials: 'include',
      body: new URLSearchParams({ username, password }),
    })
    authStore.setState({ userId, isAuthenticating: false })
  } catch (e) {
    authStore.setState({ isAuthenticating: false, hadError: true })
  }
}

export const logout = () =>
  authStore.setState({
    userId: undefined,
    isLoggedIn: false,
  })

// We use setTimeout here to run this after the initial script cycle, so that all dependencies have been setup before
// they are used in the functions above. If we'd omit this there'd be a high chance of receiving reference errors, as
// the imported functions might not be created just yet. This is probably only a problem in development, though - in
// production everything will be packed into a bundle.. but who knows, I sure don't.
setTimeout(() => {
  const cachedState = window.localStorage.getItem('auth')
  if (cachedState) authStore.setState(JSON.parse(cachedState))
}, 50)
