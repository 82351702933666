import { create } from 'zustand'

declare var flowBiConfig: ConfigStore

type ConfigStore = {
  revision?: string
  version?: string
  buildTime?: string
  apiUrl?: string
  environment?: 'preview' | 'production'
  chartsUrl?: string
}

export const configStore = create<ConfigStore>((set, get) => ({}))
export const useConfig = configStore

const injectedConfig = flowBiConfig

// Grab the config values from the global configuration map provided via index.html
if (!injectedConfig) {
  // Whoa, how the hell did that happen?
} else {
  if (injectedConfig.revision === '__REVISION__') {
    injectedConfig.revision = '000000000000'
  }

  if (injectedConfig.buildTime === '__BUILD_TIME__') {
    injectedConfig.buildTime = new Date().toLocaleString()
  } else {
    injectedConfig.buildTime = new Date(Number(flowBiConfig.buildTime) * 1000).toLocaleString()
  }

  if (injectedConfig.apiUrl === '__API_URL__') {
    injectedConfig.apiUrl = 'http://localhost:8080'
  }

  if (injectedConfig.chartsUrl === '__CHARTS_URL__') {
    injectedConfig.chartsUrl = 'https://charts.dev.flbi.net'
  }

  if (!['preview', 'production'].includes(injectedConfig.environment || '')) {
    // If not defined, always assume we're in production.
    injectedConfig.environment = 'production'
  }

  injectedConfig.version = 'v.' + (injectedConfig.revision || '00000000').substring(0, 8)

  configStore.setState(injectedConfig)
}
