import { create } from 'zustand'
import { request } from '../api'
import { authStore } from '../auth'
import { init as initPermissions } from './currentPermissions'

interface User {
  id?: string
  email?: string
  name?: string
  perspective?: string
  preferred_language?: string
  hide_hints?: boolean
  database_user?: string
  use_vr_data_model?: boolean
  avatar?: string

  setId: (id: string) => Promise<void>
  refresh: () => Promise<void>
  update: (properties: {}) => Promise<void>
  changePassword: (oldpass: string, newpass: string) => Promise<void>
}

export const userStore = create<User>((set, get) => ({
  setId: async newId => {
    //try {
    const { data: account } = await request('/accounts/' + newId)
    set(() => ({ ...account }))
    /*} catch (e: any) {
            console.error('Could not fetch the account', e)
            
            if (e.cause && e.cause < 500) {    
                // Better safe then sorry, let's kick this account out. This might be a broken JWT, or even
                // a stolen one. We shouldn't allow retries and push the user back to the login screen.
                // Note to future self: if we introduce an info modal on the login screen any time in the
                // future, this would be perfect for a tiny notice á la "yo, your session was terminated".
                logout()
            }
        }*/
  },
  refresh: async () => {
    await get().setId(get().id || '')
  },
  update: async (properties: {}) => {
    set(properties)
    await request(`/accounts/${get().id}`, {
      method: 'put',
      jsonBody: {
        email: get().email,
        name: get().name,
        perspective: get().perspective,
        preferred_language: get().preferred_language,
        hide_hints: get().hide_hints,
        database_user: get().database_user,
        use_vr_data_model: get().use_vr_data_model,
        avatar: get().avatar,
      },
    })
  },
  changePassword: async (oldpass: string, newpass: string) => {
    await request(`/accounts/${get().id}/change-password`, {
      method: 'put',
      jsonBody: {
        oldpass,
        newpass,
      },
    })
  },
}))
export const useUser = userStore

// Holds a reference to our backend ping timeout. We're using manual timeouts here to have at least a bit control over
// the request stack and to avoid bundling up waiting ones. This of course is a pretty creppy attempt at a long polling
// mechanism but it'll work for now. Keep in mind that .setId() will invoke a request() to the API, and our centralized
// request() function will kick all our auth stores out of the water if it receives a 403 - ending our next timeout, so
// we should be kinda safe against runaway timers.
let pingCycle: number
const pingBackend = () =>
  window.setTimeout(() => {
    window.clearTimeout(pingCycle)
    userStore.getState().setId('me')
    pingCycle = pingBackend()
  }, 60000)

export const init = async () => {
  // Kick off the store with "mine" to always stick to the current users own client if nothing else is specified.
  // Note how this will automatically load the correct name and real id from the API via the setter seen above.
  await userStore.getState().setId('me')

  // Update our user every minute to update our tokens.
  pingCycle = pingBackend()

  // Make sure to fetch the current permissions of the user.
  await initPermissions()

  // Make sure to wipe the current user if no token is present.
  authStore.subscribe(
    state => state.isLoggedIn,
    async (isLoggedIn?: boolean) => {
      if (isLoggedIn) return
      window.clearTimeout(pingCycle)
      userStore.setState(state => ({
        setId: state.setId,
        refresh: state.refresh,
        update: state.update,
      }))
    }
    // state => state.isLoggedIn
  )

  return true
}
