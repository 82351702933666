import { useCallback, useEffect, useRef, useState } from 'react'
// import { logout } from './auth'
import { clientStore } from './auth/currentClient'
import { configStore } from './config'
import { handleApiError, useApiError } from './apiError'

export interface ApiRequestOptions extends RequestOptions {
  queryParameters?: { [key: string]: any }
}

export const request = async (
  path: string,
  options: ApiRequestOptions = {}
): Promise<{
  data: any
  response: Response
  recordCount: number
}> => {
  const requestParams = { path: path + '', options: { ...options } }
  const requestId = crypto.randomUUID()
  const { apiUrl, environment } = configStore.getState()
  const headers = options.headers ? (options.headers as Headers) : new Headers()

  headers.set('x-request-id', requestId)

  if (options.jsonBody) {
    options.body = JSON.stringify(options.jsonBody)
    headers.set('Content-Type', 'application/json')
  }

  options.headers = headers

  if (environment === 'production') {
    options.credentials = 'same-origin'
  } else {
    // Make sure the JWT cookie is stored and sent along even in development. This is needed because we run the API
    // and frontend on two separate processes currently, bringing each up on a different port. This makes browsers
    // ignore the JWT cookie and essentially breaks our authentication mechanism.
    options.credentials = 'include'
  }

  const clientId = clientStore.getState().gsr_client
  if (!('gsr_client' in (options.queryParameters || {})) && clientId) {
    // Make sure we always limit our requests to the current client to keep the interface streamlined.
    // Todo: now that the API is restricted to a single client via the DB we can probably drop this.
    options.queryParameters = { ...options.queryParameters, gsr_client: clientId }
  }

  const queryParameters = Object.keys(options.queryParameters || {})
    .map(x => x + '=' + options.queryParameters?.[x])
    .join('&')
  // Make sure not to ruin everyones day when somebody forgets to set the query parameters via the
  // proper option entry.
  const querySymbol = path.includes('?') ? '&' : '?'
  let response: Response
  let text = ''
  let data: any
  let recordCount = 0

  try {
    response = await fetch(apiUrl + path + querySymbol + queryParameters, options)

    text = await response.text()
    data = text && JSON.parse(text)

    recordCount = parseInt(response.headers.get('x-total-records') || '0', 10)
  } catch (e) {
    // Simulate a gateway timeout to provoke a matching error message to the user.
    // This is probably the most ideal and unproblematic way of doing error handling.
    // Note that we also fully ignore what really went wrong to be of as little help
    // as possible.
    // @ts-ignore
    response = { status: 502, ok: false }
    console.trace(e)
  }

  if (response.ok) {
    // if isServerNotReachable error is present, Clears the error when next backend ping returns OK response.
    // this clear our serverNotReachable ApiErrorNotification pop-up, giving user a sort of auto-connect feel.
    const isServerNotReachableError = useApiError.getState().isServerNotReachable
    if (isServerNotReachableError) {
      useApiError.getState().dismiss()
    }
  }

  if (!response.ok) {
    console.log(
      `🔰 Response not ok | status-code ${response.status} \n (Error should pop-up on screen)`
    )

    // set statusCode in apiError state
    useApiError.getState().setStatusCode(response.status)

    // set serverErrorResponse in apiError state
    if (data && data.message) {
      console.log('Response message:', data.message)
      useApiError.getState().setServerErrorResponse(data.message)
    } else {
      console.log('No error message available in response data')
    }

    return new Promise((resolve, reject) =>
      handleApiError(
        response,
        data,
        requestId,
        requestParams,
        async function onRetry() {
          console.log('Retrying request...')
          const result = await request(requestParams.path, requestParams.options)

          if (result) {
            resolve(result)
          }
        },
        function onDismiss() {
          console.log('onDismiss() executed')
          // This may not be elegant but it prevents the chain of promises from being executed without any
          // actual data. We should work on improving error handling in all components, including the ability
          // of them to handle empty data (cause that still might happen).
          reject((data && data.message) || response.status)
          throw Error((data && data.message) || response.status, { cause: response.status })
        }
      )
    )
  }
  return { data, response, recordCount }
}

export interface RequestOptions extends RequestInit {
  jsonBody?: {}
}

export interface IPaginationResult<Type> {
  pageSize: number
  page: number
  order?: 'desc' | 'asc'
  sortBy?: string
  setPageSize: (pageSize: number) => void
  setPage: (page: number) => void
  setOrder: (order: 'desc' | 'asc') => void
  setSortBy: (sortBy: string) => void
  recordCount: number
  records: Type[]
  refresh: () => void
  loading: boolean
  loadNextPage: () => void
}

export interface IPaginationParameters {
  pageSize?: number
  page?: number
  sortBy?: string
  order?: 'desc' | 'asc'
  queryParameters?: {}
}

const DEFAULT_QUERY_PARAMETERS = {}

export const usePaginatedRequest = <Type>(
  path: string,
  {
    pageSize: initialPageSize = 50,
    page: initialPage = 1,
    sortBy: initialSortBy = undefined,
    order: initialOrder = undefined,
    queryParameters = DEFAULT_QUERY_PARAMETERS,
  }: IPaginationParameters = {}
): IPaginationResult<Type> => {
  const [pageSize, setPageSize] = useState(initialPageSize)
  const [page, setPage] = useState(initialPage)
  const [sortBy, setSortBy] = useState(initialSortBy)
  const [order, setOrder] = useState(initialOrder)
  const [recordCount, setRecordCount] = useState(0)
  const [records, setRecords] = useState([])
  const [effectBreaker, setEffectBreaker] = useState(0)
  const [loading, setLoading] = useState(true)

  const loadingRef = useRef(loading)

  useEffect(() => {
    loadingRef.current = loading
  }, [loading])

  const loadNextPage = useCallback(() => {
    if (loadingRef.current) {
      // Don't overrun ourselves here.
      return
    }

    setPage(page => page + 1)
  }, [])

  // We bake useEffect right in so we don't fetch the result on every rerender but only when the parameters change.
  // This might look odd at first sight, but this syntax just works around the limitation of not being able to directly
  // use async logic as effect callbacks. Instead we use an async IIFE here to save boilerplate code.  And yes, by the
  // time of writing this I reckon I could have simply put the more redundant code here instead of writing a book to
  // document the "shorter" variant. But it still looks cool, so I'll keep it. That's just how awesome I am.
  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const { data, recordCount } = await request(path, {
        queryParameters: {
          page,
          pageSize,
          ...(order && sortBy ? { order, sortBy } : {}),
          ...queryParameters,
        },
      })

      setRecords(data)
      setRecordCount(recordCount)
      setLoading(false)
    })()
  }, [path, pageSize, page, effectBreaker, order, sortBy, queryParameters])

  const refresh = useCallback(
    () => setEffectBreaker(effectBreaker => effectBreaker + 1),
    [setEffectBreaker]
  )

  return {
    pageSize,
    page,
    setPageSize,
    setPage,
    recordCount,
    records,
    loading,
    refresh,
    order,
    setOrder,
    sortBy,
    setSortBy,
    loadNextPage,
  }
}
