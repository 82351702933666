import { PropsWithChildren, useEffect, useState, useRef, ComponentProps } from 'react'
import { setLicenseKey, Tooltip } from '@grapecity/wijmo'
import { FlexGrid } from '@grapecity/wijmo.react.grid'
import '@grapecity/wijmo.styles/wijmo.css'
import { CollectionViewNavigator } from '@grapecity/wijmo.react.input'
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter'
import Box from '@mui/material/Box'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { ApiRequestOptions } from '../state/api'
import { ApiConnectorCollectionView, GridEventHandler } from './DataGrid/ApiConnectorCollectionView'

// Get rid of the pesky demo banner.
setLicenseKey('mkerwitz@flow.bi,E295319762894931#B0MZHOiI7ckJye0ICbuFkI1pjIEJCLi4TPnFEZtRjb7ZDWxBDVmtmQ5JTMUh5ZwckcsZkcZN6LJlndp3Se5smdRhXSZlVVwIVWqREbzM5MUFWUuNFNJJVZVl4UFR7czZGTMJEewE4Rm5kMrJjelZlNWpEcrkVYxRVTUBHd4NjVxh5KaZ7Nw4UbLNGO5cUSm9kdptiSSZGWntSThlWarBzMSdWToVzVBVUMvc5TNB5ZwoUe5EkWuNlWIdzbsR6T0Z5c9skeIFzc6VjbFNkYSFzVzQXYWJmVxM6UnRVU9EXTwsWVltGSvJnTTVmVzc7LPdmWFNjT4w6T0ZVc9tEd5I6K7QkM5VGRykFcC5kaRhWduVTbihzco3SRUZ4dmdTO9cnR6pHWzU4UqdjcCtGM8JXR8g6Z8pGZPdVMTV4ZrZkZxt6ZZtkRCZjaNZkYsp6dvQkRuJTQ8gTR6hlcpZ4bYllewlzNxNzcThDTyMzb9R5ViojITJCLiYEOEljM5U4MiojIIJCL6UDO7gzN9EjM0IicfJye#4Xfd5nIJBjMSJiOiMkIsIibvl6cuVGd8VEIgQXZlh6U8VGbGBybtpWaXJiOi8kI1xSfiUTSOFlI0IyQiwiIu3Waz9WZ4hXRgAicldXZpZFdy3GclJFIv5mapdlI0IiTisHL3JyS7gDSiojIDJCLi86bpNnblRHeFBCI73mUpRHb55EIv5mapdlI0IiTisHL3JCNGZDRiojIDJCLi86bpNnblRHeFBCIQFETPBCIv5mapdlI0IiTisHL3JyMDBjQiojIDJCLiUmcvNEIv5mapdlI0IiTisHL3JSV8cTQiojIDJCLi86bpNnblRHeFBCI4JXYoNEbhl6YuFmbpZEIv5mapdlI0IiTis7W0ICZyBlIsISNygDM7ADIwMTNwMjMwIjI0ICdyNkIsISOyYDMzIDMyIiOiAHeFJCLikmYuc7bsZGQ0RXa7JXZr5mI0ISYONkIsUWdyRnOiwmdFJCLiEzM9QTO8IjN7kTMzUTOyIiOiQWSiwSfdtlOicGbmJCLiIjdzIDMyIiOiIXZ6JCLhNLb')
 
interface DataGridProps {
    endpoint: string,
    FlexGridProps?: ComponentProps<typeof FlexGrid>,
    requestOptions?: ApiRequestOptions,
    processRowUpdate?: GridEventHandler,
    processRowCreation?: GridEventHandler,
    processRowRemoval?: GridEventHandler,
}
 
const defaultFlexGridProps: ComponentProps<typeof FlexGrid> = {
    selectionMode: 'MultiRange',
    autoGenerateColumns: false,
    alternatingRowStep: 0,
    treeIndent: 30,
    showSelectedHeaders: 'All',
    showMarquee: true,
    deferResizing: true,
    keyActionTab: 'Cycle',
}

export const DataGrid = (props: PropsWithChildren<DataGridProps>) => {
    const gridInstance = useRef<any>()
    const tooltip = useRef<any>()
    const collectionView = useRef<any>([])
    const flexGridProps = { ...defaultFlexGridProps, ...props.FlexGridProps }
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (!tooltip.current) {
            tooltip.current = new Tooltip()
            tooltip.current.showDelay = 500
        }
    }, [])

    // Remove the pesky demo banner so we can enjoy the evaluation period for a while.
    // This is super compliant with their license of course, no legal trouble forseeable at all.
    useEffect(() => { const interval = setInterval(() => {document.evaluate('//a[contains(text(),\'Wijmo Evaluation Version\')]', document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue?.parentElement?.remove()}, 1000); return () => clearInterval(interval)}, [])

    useEffect(() => {
        collectionView.current = new ApiConnectorCollectionView(
            props.endpoint,
            props.requestOptions,
            props.processRowUpdate,
            props.processRowCreation,
            props.processRowRemoval,
        )

        collectionView.current.loading.addHandler(() => setIsLoading(true))
        collectionView.current.loaded.addHandler(() => setIsLoading(false))
    }, [
        props.processRowCreation, 
        props.processRowUpdate, 
        props.processRowRemoval,
        props.requestOptions,
        props.endpoint,
    ])

    const initGrid = (grid: any) => {
        gridInstance.current = grid

        if (!gridInstance.current) return
        flexGridProps.initGrid && flexGridProps.initGrid(grid)
    }

    return <Box sx={{ 
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        flex: 1, 
        height: '100%', 
        width: '100%',
        fontSize: '.85em',
        '.wj-flexgrid': { 
            height: '100%',
        },
        '.wj-flexgrid .wj-cell': {
            transition: 'background .3s',
        },
        '.wj-flexgrid .wj-marquee, .wj-flexgrid .wj-header': {
            transition: 'all .3s',
        },
    }}>            
        <Box sx={{ 
            position: 'relative',
            flex: 1,
            overflow: 'hidden',
            mb: 1,
        }}>
            <Backdrop
                sx={{ position: 'absolute', color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <FlexGrid 
                {...flexGridProps} 
                initialized={initGrid} 
                itemsSource={collectionView.current}
                formatItem={(s: any, e: any) => {
                    if (e.panel === s.cells) {
                        var item = s.rows[e.row].dataItem
                        item && tooltip.current.setTooltip(e.cell, '')
                    }
                }}>
                <FlexGridFilter />
                {props.children}
            </FlexGrid>
        </Box>
        <Box sx={{ margin: '0 auto', }}>
            <CollectionViewNavigator 
                byPage={true} 
                headerFormat="Page {currentPage:n0} of {pageCount:n0}" 
                cv={collectionView.current}/>
        </Box>
    </Box>
}